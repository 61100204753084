import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'raffle',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由前置守卫
// let token = Vue.cookie.get('token')
router.beforeEach((to, from, next) => {
  // 判断是否登录
  if (to.path === '/login') {
    next()
  } else {
    // 判断是否已登录
    if (Vue.cookie.get('token')) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
